import { useEffect } from 'react';
import dayjs from 'dayjs';

export const useUpdateTimers = (
    cutoff: dayjs.Dayjs,
    start: dayjs.Dayjs,
    end_date: dayjs.Dayjs,
    loadProjects: () => Promise<void>,
) => {
    // set up timers for the updates
    useEffect(() => {
        const now = dayjs();
        const is_after_cutoff = now.isAfter(cutoff);
        const is_after_start = now.isAfter(start);
        const is_after_end = now.isAfter(end_date);

        let cutoff_handler: NodeJS.Timeout;
        let start_handler: NodeJS.Timeout;
        let end_handler: NodeJS.Timeout;

        if (!is_after_cutoff)
            cutoff_handler = setTimeout(
                loadProjects,
                Math.abs(now.diff(cutoff, 'ms')),
            );

        if (!is_after_start)
            start_handler = setTimeout(
                loadProjects,
                Math.abs(now.diff(start, 'ms')),
            );

        if (!is_after_end)
            end_handler = setTimeout(
                loadProjects,
                Math.abs(now.diff(end_date, 'ms')),
            );

        return () => {
            clearTimeout(cutoff_handler);
            clearTimeout(start_handler);
            clearTimeout(end_handler);
        };
    }, [cutoff, start, end_date, loadProjects]);
};
