import { commify } from '@ethersproject/units';
import dayjs from 'dayjs';
import { NextRouter } from 'next/router';

import {
    BASE_IMAGE_URL,
    CONTRACT,
    DEFAULT_CHAIN_ID,
    NETWORKS,
} from '@src/config';
import { EventType } from '@src/ts/constants';
import { ICompletedEvent } from '@src/ts/interfaces';
import { generateSlug, nFormatter } from '@src/utils/format';

export const getDataArr = (
    data: ICompletedEvent[],
    router: NextRouter,
    nav: EventType,
) => {
    if (!data) {
        return [];
    }

    return data?.map(
        ({
            name,
            project_slug,
            fdv,
            network,
            num_whitelisted,
            ath_roi,
            ath,
            ticker,
            total_raised,
            ended_date,
            total_distributed,
            hardcap = 0,
            project_logo,
            project_id,
        }) => {
            const { symbol: payment_symbol } =
                CONTRACT.PaymentToken[network || DEFAULT_CHAIN_ID];

            const event_slug = generateSlug(name);

            // use 1 if hardcap === 0 as 0/0 === NaN
            const percent_filled = ((total_raised / hardcap) * 100).toFixed(1);

            const _num_whitelisted = num_whitelisted || 0;

            return {
                name: name,
                logo: `${BASE_IMAGE_URL}/${project_id}/${project_logo}`,
                handleEventClick: () =>
                    router.push(`/project/${project_slug}/${event_slug}`),
                token_symbol: ticker,
                fvd: ath === 0 ? 'N/A' : `${nFormatter(fdv)} USD`,
                athroi:
                    ath === 0
                        ? { value: 'N/A', color: 'text-primary' }
                        : {
                              value:
                                  ath_roi > 0
                                      ? `+${commify(ath_roi.toFixed(2))}%`
                                      : `${commify(ath_roi.toFixed(2))}%`,
                              color:
                                  ath_roi > 0 ? 'text-success' : 'text-error',
                          },
                ath: {
                    value: ath === 0 ? 'N/A' : commify(ath.toFixed(4)),
                },
                total_raised:
                    nav === EventType.Crowdfunding
                        ? {
                              top_value: `${nFormatter(
                                  total_raised,
                              )} ${payment_symbol}`,
                              bottom_value: percent_filled
                                  ? `${percent_filled}% funded`
                                  : null,
                          }
                        : undefined,
                participants: commify(_num_whitelisted),
                end_date: {
                    top_date: dayjs(Number(ended_date) * 1000).format(
                        'D MMM, YYYY',
                    ),
                    bottom_time: dayjs(Number(ended_date) * 1000).format(
                        'h:mmA',
                    ),
                },
                total_distributed: `${nFormatter(total_distributed)} ${ticker}`,
                network_logo:
                    NETWORKS[network || DEFAULT_CHAIN_ID].network_logo,
            };
        },
    );
};
