import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Media, Typography } from '@decub8/ui';

import { Project } from '@src/ts/interfaces';

const buttonClasses = (disabled: boolean) => {
    const baseClasses =
        'bg-surface-level-two h-11 w-11 low-border flex items-center justify-center rounded-lg flex-shrink-0';
    return `${baseClasses} ${
        disabled ? 'opacity-50' : 'hover:bg-surface-level-three'
    }`;
};

export const MainEventSlideshow: React.FC<{
    main_projects: Project[];
    className?: string;
    setCurrentMainEvent?: (project: Project) => void;
    current_main_project?: Project;
    is_mobile?: boolean;
}> = ({
    main_projects,
    className = '',
    setCurrentMainEvent,
    current_main_project,
    is_mobile = false,
}) => {
    const [is_overflowing, setIsOverflowing] = useState(false);
    const [is_interacting, setIsInteracting] = useState(false);

    const current_index = main_projects.findIndex(
        (project) =>
            project.events[0].id === current_main_project?.events[0]?.id,
    );

    const is_first_selected = current_index === 0;
    const is_last_selected = current_index === main_projects.length - 1;

    const itemRefs = useRef<(HTMLButtonElement | null)[]>([]);
    const containerRef = useRef<HTMLDivElement | null>(null);

    const handlePrevious = useCallback(() => {
        if (current_index > 0 && setCurrentMainEvent) {
            setCurrentMainEvent(main_projects[current_index - 1]);
            setIsInteracting(true);
        }
    }, [current_index, main_projects, setCurrentMainEvent]);

    const handleNext = useCallback(() => {
        if (current_index < main_projects.length - 1 && setCurrentMainEvent) {
            setCurrentMainEvent(main_projects[current_index + 1]);
            setIsInteracting(true);
        }
    }, [current_index, main_projects, setCurrentMainEvent]);

    // whenever the current event changes and user has interacted scroll to the active button
    useEffect(() => {
        if (current_index >= 0 && is_interacting) {
            const activeBtn = itemRefs.current[current_index];
            if (activeBtn?.scrollIntoView) {
                activeBtn.scrollIntoView({
                    behavior: 'smooth',
                    inline: 'center',
                    block: 'nearest',
                });
            }
        }
    }, [current_index, current_main_project, is_interacting]);

    // check for overflow
    useEffect(() => {
        const el = containerRef.current;
        function checkOverflow() {
            if (el) {
                setIsOverflowing(el.scrollWidth > el.clientWidth);
            }
        }
        checkOverflow();

        window.addEventListener('resize', checkOverflow);
        return () => {
            window.removeEventListener('resize', checkOverflow);
        };
    }, [main_projects]);

    return (
        <div
            className={`flex items-center justify-center  ${
                is_mobile ? 'mb-[30px]' : 'mt-[30px]'
            } ${className}`}
        >
            {/* Left arrow button */}
            <button
                onClick={handlePrevious}
                disabled={is_first_selected}
                className={buttonClasses(is_first_selected)}
            >
                <Media
                    variant="chevron-left"
                    size={0}
                    className="h-5 w-[11px]"
                />
            </button>

            <div className="relative overflow-hidden mx-3">
                {/* scrollable container */}
                <div
                    ref={containerRef}
                    className="whitespace-nowrap flex overflow-x-auto"
                >
                    {main_projects.map((project, index) => {
                        const { events, name } = project;
                        const event = events[0];

                        return (
                            <button
                                key={event.id}
                                ref={(el) => {
                                    itemRefs.current[index] = el;
                                }}
                                className="px-[12px]"
                                onClick={() => {
                                    setCurrentMainEvent?.(project);
                                    setIsInteracting(true);
                                }}
                            >
                                <Typography
                                    variant={
                                        event.id ===
                                        current_main_project?.events[0]?.id
                                            ? 'primary'
                                            : 'secondary'
                                    }
                                    allBold
                                >
                                    {name}
                                </Typography>
                            </button>
                        );
                    })}
                </div>

                {/* left fade */}
                {is_overflowing && !is_first_selected && (
                    <div className="pointer-events-none absolute top-0 left-0 h-full w-8 bg-gradient-to-r from-black/80 to-transparent" />
                )}

                {/* right fade */}
                {is_overflowing && !is_last_selected && (
                    <div className="pointer-events-none absolute top-0 right-0 h-full w-8 bg-gradient-to-l from-black/80 to-transparent" />
                )}
            </div>

            {/* Right arrow button */}
            <button
                onClick={handleNext}
                disabled={is_last_selected}
                className={buttonClasses(is_last_selected)}
            >
                <Media
                    variant="chevron-right"
                    size={0}
                    className="h-5 w-[11px]"
                />
            </button>
        </div>
    );
};
