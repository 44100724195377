import React, { useMemo } from 'react';
import { BreakPoint, Typography, useBreakpoint } from '@decub8/ui';
import dayjs from 'dayjs';

import { getExchangeData } from '@src/components/Layout/Header/hooks/utils';
import { EventType } from '@src/ts/constants';
import { ExchangeType, ProjectEvent, ProjectToken } from '@src/ts/interfaces';
import { ExchangeTypes } from '@src/ts/types';
import { formatTime } from '@src/utils/format';

const getVestingDetails = (token: ProjectToken, event?: ProjectEvent) => {
    const {
        type,
        event_details: { vesting },
        token_price,
        is_vesting_dates_not_confirmed,
    } = event || { event_details: {} };

    const start_date = vesting ? new Date(vesting.start * 1000) : null;

    const formatted_start_date = start_date
        ? dayjs(start_date).utc().format('MMM D, YYYY | HH:mm UTC')
        : null;

    return [
        {
            value: !isNaN(vesting?.initial_unlock_percent)
                ? vesting.initial_unlock_percent === 0
                    ? 'None'
                    : `${vesting?.initial_unlock_percent / 10}%`
                : 'TBA',
            label:
                event?.type === EventType.TokenClaim
                    ? 'Release on vesting start'
                    : 'Release on CEX listing',
        },
        {
            value: formatted_start_date
                ? is_vesting_dates_not_confirmed
                    ? 'TBA'
                    : formatted_start_date
                : 'TBA',

            label:
                event?.type === EventType.TokenClaim
                    ? 'Vesting start'
                    : 'CEX listing',
        },
        {
            value: !isNaN(vesting?.cliff)
                ? vesting.cliff === 0
                    ? 'None'
                    : formatTime(vesting?.cliff)
                : 'TBA',
            label: 'Cliff duration',
        },
        {
            value: vesting?.duration ? formatTime(vesting?.duration) : 'TBA',
            label: 'Vesting duration',
        },

        {
            value:
                type === EventType.TokenClaim
                    ? token_price
                    : token_price && token_price !== 'TBA'
                    ? `${token_price} USD`
                    : 'TBA',
            label: 'Event token price',
        },
    ].filter(({ value }) => !!value);
};

const VestingDetails: React.FC<{
    token: ProjectToken;
    event: ProjectEvent;
    exchanges: ExchangeType[];
}> = ({ token, event, exchanges }) => {
    const vesting_details = useMemo(
        () => getVestingDetails(token, event),
        [token, event],
    );

    const breakpoint = useBreakpoint();
    const is_mobile = breakpoint < BreakPoint.SM;

    return (
        <div className="max-w-[800px] mx-auto">
            <Typography allBold className="mt-[60px]" size="3xl">
                Vesting details
            </Typography>
            <div className="flex h-px flex-col justify-center items-start self-stretch bg-surface-level-two my-5"></div>
            {vesting_details.map(({ label, value }, index) => {
                const should_apply_gradient = index % 2 === 0; // Apply gradient to even indices
                return (
                    <div
                        key={label + index + value}
                        className={`max-w-[780px] h-[60px] grid grid-cols-2 items-center px-8 gap-6
                             ${
                                 should_apply_gradient
                                     ? ''
                                     : 'bg-gradient-to-r from-surface-level-one rounded-lg to-transparent'
                             }`}
                    >
                        <Typography variant="secondary" size="md">
                            {label}
                        </Typography>
                        <Typography
                            size={is_mobile ? 'lg' : '2xl'}
                            allBold
                            className="sm:pl-2"
                        >
                            {value}
                        </Typography>
                    </div>
                );
            })}

            {exchanges?.length > 0 ? (
                <div className="my-[60px]">
                    <Typography allBold className="mt-[60px]" size="3xl">
                        Exchanges
                    </Typography>
                    <div className="flex h-px flex-col justify-center items-start self-stretch bg-surface-level-two my-5"></div>

                    <div className="flex flex-wrap gap-x-[118px] gap-y-[40px] my-8">
                        {exchanges?.map(({ name }) => {
                            const exchange_name =
                                name?.toLowerCase() as ExchangeTypes;

                            const { title, image } =
                                getExchangeData(exchange_name) || {};

                            return (
                                <div
                                    className="flex items-center gap-2"
                                    key={name}
                                >
                                    <img
                                        src={image}
                                        alt={title}
                                        className="w-[28px] h-[28px] rounded-lg"
                                    />
                                    <Typography allBold size="2xl">
                                        {title}
                                    </Typography>
                                </div>
                            );
                        })}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default VestingDetails;
