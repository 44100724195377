import { useEffect, useState } from 'react';
import { isAddress } from '@ethersproject/address';
import { log } from '@logtail/next';

import { ContractType } from '@src/ts/constants';

import { useGlobalContext } from '../useGlobalContext';

export const useToken = (address: string, chainId?: number) => {
    const { contract_manager } = useGlobalContext();
    const [symbol, setSymbol] = useState('');
    const [decimals, setDecimals] = useState(18);

    useEffect(() => {
        if (!address || !isAddress(address)) return;

        const contract = contract_manager.getContractByAddress(
            address,
            ContractType.ERC20,
            chainId,
        );

        Promise.all([contract.contract.symbol(), contract.contract.decimals()])
            .then(([symbol, decimals]) => {
                setSymbol(symbol);
                setDecimals(decimals);
            })
            .catch((err) =>
                log.error('error getting token details', {
                    err,
                    address,
                    chainId,
                }),
            );
    }, [address, chainId, contract_manager]);

    return {
        symbol,
        decimals,
    };
};
