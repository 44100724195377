import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Header as HeaderUI, Media, Typography } from '@decub8/ui';
import { motion } from 'framer-motion';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { useAppSelector } from '@src/hooks/index';
import { useInvestmentContext } from '@src/hooks/usePortfolioContext';
import { getTierOfUser } from '@src/utils/getters';

import { useTier } from './hooks/useTier';
import { useHeader } from './hooks';

const Header: React.FC<{
    className?: string;
}> = ({ className }) => {
    const props = useHeader();
    const { user, loading } = useAppSelector((state) => state.auth);
    const userTier = useTier();
    const [showDiamondClub, setShowDiamondClub] = useState(false);
    const [hideDiamond, setHideDiamond] = useState(false);
    const router = useRouter();
    const currentPath = router.pathname;

    useEffect(() => {
        const hideDiamond = localStorage.getItem('hide_diamond');
        if (hideDiamond) {
            const hideDiamondDate = parseInt(hideDiamond);
            if (new Date().getTime() < hideDiamondDate) {
                console.log('hide diamond');
                setHideDiamond(true);
            } else {
                console.log('show diamond');
                localStorage.removeItem('hide_diamond');
            }
        }
    }, []);

    const { refund_complete_modal_open, refund_modal_open } =
        useInvestmentContext();

    useEffect(() => {
        if (loading || !user || !userTier) return;
        const checkTier = async () => {
            const tier = await getTierOfUser();
            if (
                tier?.id >= 5 &&
                !user.telegram_id &&
                currentPath !== '/diamond-club' &&
                userTier.tier_img_url !== '' &&
                !hideDiamond
            ) {
                setShowDiamondClub(true);
            }
        };

        checkTier();
    }, [router, user, loading, userTier]);

    const handleCloseClick = () => {
        setShowDiamondClub(false);
        toast.info(
            <div>
                <button
                    // set hide_diamond in localstorage to date.now + 30 days
                    onClick={() => {
                        const hideDiamond =
                            new Date().getTime() + 30 * 24 * 60 * 60 * 1000;
                        localStorage.setItem(
                            'hide_diamond',
                            hideDiamond.toString(),
                        );
                    }}
                >
                    Click here to hide the Diamond Club banner in the future
                </button>
            </div>,
        );
    };

    return refund_complete_modal_open || refund_modal_open ? null : (
        <div>
            <HeaderUI {...props} className={className} />
            {showDiamondClub ? (
                <motion.div
                    initial={{ height: 0 }}
                    animate={{ height: 'auto' }}
                    transition={{ duration: 0.3, delay: 4 }}
                    className="z-50 overflow-hidden bg-gradient-purple p-2 flex flex-col md:flex-row md:items-center md:justify-center"
                >
                    <Image
                        src={userTier?.tier_img_url || ''}
                        alt="diamond-tier"
                        width={24}
                        height={24}
                        className="hidden md:block"
                    />
                    <Typography
                        className="py-1 px-1 flex flex-row items-center justify-between gap-4"
                        size="sm"
                    >
                        <a href="/diamond-club" className="hidden md:block">
                            Join the Decubate Diamond Club on Telegram, our most
                            exclusive community for top-tier investors!
                        </a>
                        <a href="/diamond-club" className="block md:hidden">
                            Join the Decubate Diamond Club on Telegram!
                        </a>
                        <div className="flex items-center justify-between flex-grow">
                            <a href="/diamond-club">
                                <div className="flex flex-row gap-1 items-center">
                                    <span>Join</span>
                                    <Media variant="arrow-right" size={4} />
                                </div>
                            </a>
                            <div onClick={() => handleCloseClick()}>
                                <Media
                                    variant="cross"
                                    size={6}
                                    className=" md:absolute md:right-2 md:top-1/2 md:translate-y-1/2 md:mt-2"
                                />
                            </div>
                        </div>
                    </Typography>
                </motion.div>
            ) : null}
        </div>
    );
};

export default Header;
