import { MainEventCardProps, Media, SocialPlatform } from '@decub8/ui';
import { BigNumber } from '@ethersproject/bignumber';
import { Contract } from '@ethersproject/contracts';
import { commify } from '@ethersproject/units';
import { ConnectedChain } from '@web3-onboard/core';
import dayjs from 'dayjs';
import { NextRouter } from 'next/router';

import {
    getTokenClaimCompleteButtonText,
    handleTokenCalimCompleteClick,
} from '@src/components/Project/Event/TokenClaimComplete';
import { DEFAULT_CHAIN_ID, NETWORKS } from '@src/config';
import { TOKEN_DECIMALS } from '@src/constants';
import { EventType } from '@src/ts/constants';
import { MarketingEvent, User } from '@src/ts/interfaces';
import { formatRelevantDecimals } from '@src/utils/format';
import { parseBalance } from '@src/utils/web3';

import {
    isAmaEventFinished,
    isAmaEventLive,
    isWithin60SecondsBefore,
} from '../utils';

export const getTokenClaimPreWhitelistProps = (
    total_allocation: string,
    chainId: number,
    has_ama_event: boolean,
    ama_event: MarketingEvent,
    marketing_event: MarketingEvent,
    social_platforms: { type: string; url: string }[],
    project_slug: string,
    event_slug: string,
    router: NextRouter,
    props_same: Partial<MainEventCardProps>,
): MainEventCardProps => {
    return {
        data: [
            {
                label: 'Total allocation',
                value: `${total_allocation || 'XXX,XXX'} USD`,
            },

            {
                label: 'Event network',
                value: NETWORKS[chainId || DEFAULT_CHAIN_ID]?.network_name,
            },
        ],
        ama_data: has_ama_event
            ? {
                  title: ama_event?.title,
                  date: dayjs(Number(ama_event?.start_date) * 1000).format(
                      'D MMMM, H:mm',
                  ),
                  youtube_url: ama_event?.link || undefined,
                  show_ama_live: isAmaEventLive(ama_event),
                  is_completed: isAmaEventFinished(ama_event),
              }
            : undefined,

        marketing_event_data: marketing_event
            ? {
                  title: marketing_event?.title,
                  image: marketing_event?.image,
                  link: marketing_event?.link,
              }
            : undefined,
        socials: social_platforms.map(({ type, url }) => ({
            name: type as SocialPlatform,
            url,
        })),
        primary_button: {
            text: 'View project',
            handleClick: () =>
                router.push(`/project/${project_slug}/${event_slug}`),
        },
        ...props_same,
    } as MainEventCardProps;
};

export const getTokenClaimWhitelistOpenProps = (
    end_date: dayjs.Dayjs,
    total_allocation: string,
    chainId: number,
    social_platforms: { type: string; url: string }[],
    project_slug: string,
    event_slug: string,
    router: NextRouter,
    props_same: Partial<MainEventCardProps>,
    user: User,
    registered: boolean,
    api_token_price: number,
): MainEventCardProps => {
    return {
        has_accent_countdown: isWithin60SecondsBefore(end_date),
        target_date: end_date.unix().toString(),
        text: 'Challenge ends in',
        network_info: {
            logo: NETWORKS[chainId || DEFAULT_CHAIN_ID]?.network_logo,
            name: NETWORKS[chainId || DEFAULT_CHAIN_ID]?.network_name,
        },
        data: [
            {
                label: 'Token live price',
                value: api_token_price
                    ? `$${formatRelevantDecimals(api_token_price, 2)}`
                    : 'Live',
            },
            {
                label: 'Total allocation',
                value: `${total_allocation}`,
            },
            {
                label: 'Your allocation',
                value: user ? 'TBA' : 'N/A',
            },
            {
                label: 'Event network',
                value: NETWORKS[chainId || DEFAULT_CHAIN_ID]?.network_name,
            },
        ],
        primary_button: user
            ? registered
                ? {
                      text: 'Go to event',
                      handleClick: () =>
                          router.push(`/project/${project_slug}/${event_slug}`),
                  }
                : {
                      text: 'Go to challenge',
                      handleClick: () =>
                          router.push(`/project/${project_slug}/${event_slug}`),
                  }
            : {
                  text: 'Log in',
                  handleClick: () => router.push('/login'),
              },
        secondary_button: !user
            ? {
                  text: 'View event',
                  handleClick: () =>
                      router.push(`/project/${project_slug}/${event_slug}`),
              }
            : undefined,

        socials: social_platforms.map(({ type, url }) => ({
            name: type,
            url,
        })),

        ...props_same,
    } as MainEventCardProps;
};

export const getTokenClaimCompleteProps = (
    total_allocation: string,
    chainId: number,
    social_platforms: { type: string; url: string }[],
    project_slug: string,
    event_slug: string,
    router: NextRouter,
    props_same: Partial<MainEventCardProps>,
    user: User,
    has_claimed: boolean,
    alloc_to_use: BigNumber,
    token_symbol: string,
    token_decimals: number,
    account: string,
    connect: () => void,
    connectedChain: ConnectedChain,
    setChainID: (id: number) => Promise<boolean>,
    is_connected_verified_wallet: boolean,
    settingChain: boolean,
    finalized: boolean,
    loading: boolean,
    uses_merkle_root: boolean,
    token_claim: Contract,
    proof: string[],
    update: () => Promise<void>,
    num_whitelisted: number,
    registered: boolean,
    time_left_to_vesting_start: string | JSX.Element,
    has_vesting_start_passed: boolean,
    setLoading: (loading: boolean) => void,
    api_token_price: number,
): MainEventCardProps => {
    const button_text =
        user && registered
            ? getTokenClaimCompleteButtonText(
                  finalized,
                  account,
                  is_connected_verified_wallet,
                  has_claimed,
              )
            : 'View event';
    return {
        data: [
            {
                label: 'Token live price',
                value: api_token_price
                    ? `$${formatRelevantDecimals(api_token_price, 2)}`
                    : 'Live',
            },
            {
                label: 'Total allocation',
                value: total_allocation,
            },
            {
                label: 'Your allocation',
                value:
                    user && has_claimed
                        ? `${parseBalance(
                              alloc_to_use,
                              TOKEN_DECIMALS[token_symbol] || token_decimals,
                          )} ${token_symbol}`
                        : 'N/A',
                button_text: !user ? 'Log in' : undefined,
                handleClick: user ? undefined : () => router.push('/login'),
            },
            {
                label: 'Event network',
                value: NETWORKS[chainId || DEFAULT_CHAIN_ID]?.network_name,
            },
        ],
        socials: social_platforms.map(({ type, url }) => ({
            name: type as SocialPlatform,
            url,
        })),
        text: has_vesting_start_passed ? undefined : (
            <>
                <Media
                    className="text-secondary h-4 w-4 mr-[10px]"
                    variant="clock"
                />
                <span>Token available to claim in</span>
                <span className="text-primary">
                    &nbsp; {time_left_to_vesting_start}
                </span>
            </>
        ),
        primary_button: {
            text: button_text,
            loading: loading || settingChain,
            disabled: button_text === 'Calculating allocation...',
            handleClick: () =>
                user && registered
                    ? handleTokenCalimCompleteClick(
                          account,
                          connect,
                          connectedChain,
                          setChainID,
                          is_connected_verified_wallet,
                          chainId || DEFAULT_CHAIN_ID,
                          has_claimed,
                          router,
                          user,
                          uses_merkle_root,
                          token_claim,
                          setLoading,
                          alloc_to_use,
                          proof,
                          update,
                      )
                    : router.push(`/project/${project_slug}/${event_slug}`),
        },
        event_type: EventType.TokenClaim,
        secondary_button:
            (user && has_claimed) || (user && registered && !has_claimed)
                ? {
                      text: 'View event',
                      handleClick: () =>
                          router.push(`/project/${project_slug}/${event_slug}`),
                  }
                : undefined,
        whitelisted_participants: commify(num_whitelisted),
        ...props_same,
    } as MainEventCardProps;
};
