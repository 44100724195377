import { useCallback, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { LoginFormProps, LoginStage } from '@decub8/ui';
import { useRouter } from 'next/router';

import { useAppDispatch, useAppSelector } from '@src/hooks';
import { IdentityVerifiedStatus } from '@src/ts/constants';

import { attemptLogin, attempVerify, State } from './utils';

export interface LoginParams {
    disable_routing?: boolean;
    loginCallback?: () => void;
    createAccountCallback?: () => void;
}

export const useLogin = (params: LoginParams = {}): LoginFormProps => {
    const { disable_routing, loginCallback, createAccountCallback } = params;

    const { user, initial_user_loaded } =
        useAppSelector((state) => state?.auth) || {};

    const router = useRouter();

    const [errors, setErrors] = useState({});
    const [state, setState] = useState({
        stage: LoginStage.Login,
        email: '',
        password: '',
        code: '',
    });
    const [loading, setLoading] = useState(false);

    const { handleLogin, handleVerify } = useHandleLogin(
        state,
        setState,
        setLoading,
        setErrors,
    );

    const disabled =
        state.stage === LoginStage.Login
            ? !state.email || !state.password
            : !state.code;

    useEffect(() => {
        if (!user || disable_routing) return;

        if (user.identity_verified === IdentityVerifiedStatus.UNVERIFIED) {
            router.push('/verify/identity');
            return;
        }

        let route = localStorage.getItem('afterLoginRoute');
        route =
            route && (route.includes('register') || route.includes('reset'))
                ? '/'
                : route;
        router.push(user.messages?.length > 0 ? '/messages' : route || '/');
    }, [user, disable_routing, router, initial_user_loaded]);

    const handleChange = async (name: string, value: string) => {
        setState({ ...state, [name]: value });
        // if the code entered in the input is of length call the handle verify
        // the success if used to check if the code is correct and no errors thrown
        if (state.stage === LoginStage.MFA && value.length === 6) {
            const { success } = await handleVerify(value);
            if (success && loginCallback) {
                loginCallback();
            }
        }
    };

    const handleClick = async () => {
        setErrors({});
        if (state.stage === LoginStage.Login) {
            const { success } = await handleLogin();

            if (success && loginCallback) loginCallback();
        } else {
            const { success } = await handleVerify(state.code);
            if (success && loginCallback) {
                loginCallback();
            }
        }
    };

    return {
        handleClick,
        state,
        handleChange,
        handleCreateAccount: createAccountCallback
            ? createAccountCallback
            : () => router.push('/register'),
        handleForgotPassword: () => router.push('/login/reset'),
        errors,
        loading: loading,
        disabled,
        className: 'w-full',
    };
};

const useHandleLogin = (
    state: State,
    setState: (obj: State) => void,
    setLoading: (b: boolean) => void,
    setErrors: (obj: Record<string, string>) => void,
) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const dispatch = useAppDispatch();

    const handleLogin = useCallback(
        async () =>
            attemptLogin(
                state,
                setState,
                setLoading,
                setErrors,
                executeRecaptcha,
                dispatch,
            ),
        [state, setState, setLoading, setErrors, executeRecaptcha],
    );

    const handleVerify = useCallback(
        (code: string) =>
            attempVerify(
                code,
                state,
                setLoading,
                setErrors,
                executeRecaptcha,
                dispatch,
            ),
        [state, setLoading, setErrors, executeRecaptcha],
    );

    return {
        handleLogin,
        handleVerify,
    };
};
