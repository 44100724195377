import { useEffect, useState } from 'react';

import { Project } from '@src/ts/interfaces';
import { getHomepageEvents } from '@src/utils/getters';

export const useLatestProjectData = (
    projects: Project[],
): {
    main_projects?: Project[];
    secondary_projects: Project[];
} => {
    const [main_projects, setMainEvents] = useState<Project[]>([]);
    const [secondary_projects, setSecondaryEvents] = useState<Project[]>([]);

    useEffect(() => {
        getHomepageEvents().then((res) => {
            if (!res) return;

            const main_event_ids = res.main_events?.data?.map((event) =>
                event.value.toString(),
            );

            const ordered_main_projects = main_event_ids
                .map((id) =>
                    projects.find(
                        (project) => project.events?.[0]?.id.toString() === id,
                    ),
                )
                .filter((project): project is Project => project !== undefined);

            setMainEvents(ordered_main_projects);

            const secondary_event_ids = res.secondary_events.data.map((event) =>
                event.value.toString(),
            );

            const ordered_secondary_projects = secondary_event_ids
                .map((id) =>
                    projects.find(
                        (project) => project.events?.[0]?.id.toString() === id,
                    ),
                )
                .filter((project): project is Project => project !== undefined);

            setSecondaryEvents(ordered_secondary_projects);
        });
    }, [projects]);

    return {
        main_projects,
        secondary_projects,
    };
};
