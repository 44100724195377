import { useEffect, useRef, useState } from 'react';
import { ApplyForIDO, useBreakpoint } from '@decub8/ui';
import { motion } from 'framer-motion';
import { GetServerSideProps } from 'next';

import { api_client } from '@src/bootstrap/index';
import { Content, ContentSection } from '@src/components';
import {
    CompletedEventsSection,
    MainEventCardContainer,
    SecondaryEventCardsContainer,
} from '@src/components/HomepageComponents';
import { AmaCard } from '@src/components/HomepageComponents/AmaCard';
import { useLatestProjectData } from '@src/components/HomepageComponents/MainEventCardContainer/hooks';
import { MainEventSlideshow } from '@src/components/HomepageComponents/MainEventSlideShow';
import { useGlobalContext } from '@src/hooks/useGlobalContext';
import { CONTENT_API } from '@src/services/content';
import { ContentSectionType } from '@src/ts/constants';
import { IContentSection, Project } from '@src/ts/interfaces';
import { getAmaEvents, getIDOSectionProps } from '@src/utils/getters';

const Home: React.FC<{
    hero_section: IContentSection;
}> = ({ hero_section }) => {
    const [current_main_project, setCurrentMainProject] = useState<Project>();
    const events_ref = useRef<HTMLDivElement>(null);

    const {
        projects,
        projects_loaded,
        loadProjects,
        loadContent,
        content_loaded,
        content_sections: {
            partner_section,
            homepage_faqs,
            ido_section,
            get_started_section,
            additional_info_section,
        },
    } = useGlobalContext();

    useEffect(() => {
        loadProjects().catch((e) => console.log('Error loading projects', e));
    }, [loadProjects]);

    useEffect(() => {
        loadContent();
    }, [loadContent]);

    const { main_projects, secondary_projects } =
        useLatestProjectData(projects);

    useEffect(() => {
        if (
            main_projects &&
            main_projects.length > 0 &&
            !current_main_project
        ) {
            setCurrentMainProject(main_projects[0]);
        }
    }, [main_projects, current_main_project]);

    const id_section_props = getIDOSectionProps(ido_section);

    const ama_events = getAmaEvents(main_projects, secondary_projects);

    const breakpoint = useBreakpoint();

    const is_mobile = breakpoint < 987;

    const is_mobile_for_slideshow = breakpoint < 450;

    return (
        <>
            <ContentSection section={hero_section} events_ref={events_ref} />
            <Content padding={false} className="mb-[200px]">
                <div className={`px-5`}>
                    <div ref={events_ref}>
                        {ama_events
                            .filter(
                                (ama_event) =>
                                    ama_event.ama_event.title &&
                                    ama_event.ama_event.youtube_url &&
                                    !ama_event.ama_event.is_complete,
                            )
                            .map((ama_event, index) => (
                                <AmaCard
                                    key={ama_event.ama_event.title || index}
                                    ama_event={ama_event.ama_event}
                                    is_mobile={is_mobile}
                                />
                            ))}

                        {current_main_project && (
                            <motion.section
                                initial={{ height: '0vh', opacity: 0 }}
                                animate={
                                    content_loaded
                                        ? { height: 'auto', opacity: 1 }
                                        : {}
                                }
                                transition={{ duration: 0.2 }}
                            >
                                {main_projects.length > 1 &&
                                    is_mobile_for_slideshow && (
                                        <MainEventSlideshow
                                            is_mobile={is_mobile_for_slideshow}
                                            current_main_project={
                                                current_main_project
                                            }
                                            main_projects={main_projects}
                                            setCurrentMainEvent={
                                                setCurrentMainProject
                                            }
                                        />
                                    )}
                                <MainEventCardContainer
                                    className={'w-full'}
                                    next_project={current_main_project}
                                />
                                {main_projects.length > 1 &&
                                    !is_mobile_for_slideshow && (
                                        <MainEventSlideshow
                                            current_main_project={
                                                current_main_project
                                            }
                                            main_projects={main_projects}
                                            setCurrentMainEvent={
                                                setCurrentMainProject
                                            }
                                        />
                                    )}
                            </motion.section>
                        )}
                        {secondary_projects.length > 0 && (
                            <motion.section
                                initial={{ opacity: 0 }}
                                transition={{ duration: 0.4 }}
                                animate={projects_loaded ? { opacity: 1 } : {}}
                            >
                                <SecondaryEventCardsContainer
                                    secondary_projects={secondary_projects}
                                    className={'mt-8'}
                                />
                            </motion.section>
                        )}
                    </div>

                    <motion.section
                        initial={{ height: '0vh', opacity: 0 }}
                        animate={
                            content_loaded ? { height: 'auto', opacity: 1 } : {}
                        }
                        transition={{ duration: 0.2 }}
                    >
                        <ContentSection
                            className={'mt-[40px] md:mt-[120px]'}
                            section={get_started_section}
                            events_ref={events_ref}
                        />
                    </motion.section>

                    {/*
                    <motion.section
                        initial={{ opacity: 0 }}
                        transition={{ duration: 0.7 }}
                        animate={content_loaded ? { opacity: 1 } : {}}
                    >
                        <UpcomingEventsContainer
                            className={'mt-[40px] md:mt-[120px]'}
                        />
                    </motion.section> */}

                    <motion.section
                        initial={{ height: '0vh', opacity: 0 }}
                        animate={
                            content_loaded ? { height: 'auto', opacity: 1 } : {}
                        }
                        transition={{ duration: 0.2 }}
                    >
                        <ContentSection
                            section={additional_info_section}
                            className={'mt-[40px] md:mt-[120px] '}
                        />
                    </motion.section>

                    {partner_section && (
                        <motion.section
                            initial={{ opacity: 0 }}
                            transition={{ duration: 0.7 }}
                            animate={content_loaded ? { opacity: 1 } : {}}
                        >
                            <ContentSection
                                className={'mt-[80px] md:mt-[120px]'}
                                section={partner_section}
                            />
                        </motion.section>
                    )}

                    <CompletedEventsSection
                        className={'mt-[80px] md:mt-[120px]'}
                    />

                    <motion.section
                        initial={{ height: '0vh', opacity: 0 }}
                        animate={
                            content_loaded ? { height: 'auto', opacity: 1 } : {}
                        }
                        transition={{ duration: 0.2 }}
                    >
                        <ApplyForIDO
                            className={'mt-[40px] md:mt-[120px]'}
                            {...id_section_props}
                        />
                    </motion.section>

                    <motion.section
                        initial={{ opacity: 0 }}
                        transition={{ duration: 0.7 }}
                        animate={content_loaded ? { opacity: 1 } : {}}
                    >
                        <ContentSection
                            className={'mt-[40px] md:mt-[120px]'}
                            section={homepage_faqs}
                        />
                    </motion.section>
                </div>
            </Content>
        </>
    );
};

export const getServerSideProps: GetServerSideProps = async () => {
    try {
        // Fetch all the required data using the unified query
        const { retrieveHeroSection: hero_section } = await api_client.query<{
            retrieveHeroSection: IContentSection;
        }>({
            query: CONTENT_API.RETRIEVE_HERO_SECTION,
            variables: {
                name: ContentSectionType.HeroSection,
            },
            fetchPolicy: 'network-only',
        });

        return {
            props: {
                hero_section,
            },
        };
    } catch (e) {
        console.log(e);
        return {
            props: {
                projects: [],
            },
        };
    }
};

export default Home;
