import React from 'react';
import { Media, Steps, Typography } from '@decub8/ui';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'; // Import the duration plugin

import { getFormattedTimeLeft } from '@src/components/HomepageComponents/MainEventCardContainer/utils';
import { DEFAULT_IDO_ROUND_DURATIONS } from '@src/constants';
import { EventType } from '@src/ts/constants';
import { ProjectEvent } from '@src/ts/interfaces';

dayjs.extend(duration); // Extend dayjs with duration

import { useAppSelector } from '@src/hooks/index';
import { useCountdown } from '@src/hooks/useCountdown';
import { useGlobalContext } from '@src/hooks/useGlobalContext';

import { useQualifySteps } from './Qualify';
import { getTimerValue, nowLiveIndex } from './utils';

// When the snpshot is done it wont go to GA stage unless I refresh

interface StageOption {
    name: string;
    component: React.FC;
    date?: string;
    props?: { [key: string]: unknown };
}

const Stage: React.FC<{
    idx: number;
    option: StageOption;
    options: StageOption[];
    active_stage: number;
    total: number;
    event: ProjectEvent;
    has_reached_cutoff: boolean;
    is_event_over: boolean;
    hardcap: string;
    total_raised: string;
}> = ({ idx, option, options, active_stage, event }) => {
    const { name, component: Component, props = {}, date } = option;

    const { user } = useAppSelector((state) => state.auth);
    const { durations = DEFAULT_IDO_ROUND_DURATIONS } =
        event?.event_details || {};

    const { _userTier, _setTierDrawerOpen, setShowAllocationModal } =
        useGlobalContext();

    const steps_to_qualify = useQualifySteps(
        user,
        _userTier,
        _setTierDrawerOpen,
        props.min_tier as number,
        setShowAllocationModal,
    );

    const whitelist_stage = idx === 0;

    const last_stage = active_stage >= options.length - 1;

    const now_live_idx = nowLiveIndex(event);

    const has_contracts = event?.contract?.abi && event?.contract?.address;

    const is_crowdfunding = event.type === EventType.Crowdfunding;

    const start_date = dayjs(Number(event.start_date) * 1000);
    const ga_round_end = start_date.add(durations[0], 'seconds');
    const fcfs1_round_end = start_date.add(
        durations[0] + durations[1],
        'seconds',
    );
    const fcfs2_round_end = start_date.add(
        durations[0] + durations[1] + durations[2],
        'seconds',
    );

    // TODO:  think this component is rerendering lots of times
    const ga_end_countdown = useCountdown(ga_round_end.toISOString());
    const fcfs1_end_countdown = useCountdown(fcfs1_round_end.toISOString());
    const fcfs2_end_countdown = useCountdown(fcfs2_round_end.toISOString());

    const time_left_to_ga_end = getFormattedTimeLeft(ga_end_countdown);
    const time_left_to_fcfs1_end = getFormattedTimeLeft(fcfs1_end_countdown);
    const time_left_to_fcfs2_end = getFormattedTimeLeft(fcfs2_end_countdown);

    const active = idx === active_stage;

    const timer_value = getTimerValue(
        idx,
        time_left_to_ga_end,
        time_left_to_fcfs1_end,
        time_left_to_fcfs2_end,
        active,
        date,
    );

    const is_qualified = steps_to_qualify.length === 0;

    // const crowdfunding_stage_complete = dayjs().isAfter(
    //     whitelist_stage
    //         ? has_reached_cutoff
    //             ? dayjs(0) // if past cutoff use date in the past for the comparison of first idx
    //             : dayjs().add(1, 'hour') // otherwise use a date in the future
    //         : start.add(durations[idx - 1] || 0, 'seconds'),
    // );

    // const has_missed_event = !registered && has_reached_cutoff;

    const is_future_stage = idx > active_stage;
    const is_past_stage = idx < active_stage;

    const show_checkmark = is_crowdfunding
        ? is_past_stage || last_stage
        : is_qualified || is_past_stage;

    // const hardcap_met = isHardcapMet(
    //     payment_token.decimals,
    //     hardcap,
    //     total_raised,
    // );

    const is_qualify_stage_of_token_claim =
        idx === 0 && !is_qualified && !is_crowdfunding;

    return (
        <div
            className={`${
                is_future_stage || !has_contracts ? 'opacity-20' : ''
            }`}
        >
            <div className={`flex justify-between items-center`}>
                <div className="flex items-center space-x-4">
                    {show_checkmark ? (
                        <Steps
                            className="h-[24px] w-[24px]"
                            variant="secondary"
                        >
                            <Media
                                size={0}
                                className="w-[17px] pt-[2px] pr-[1px]"
                                variant="tick"
                            />
                        </Steps>
                    ) : active && !is_qualify_stage_of_token_claim ? (
                        <span className="relative flex items-center justify-center h-6 w-6">
                            <span className="animate-ping absolute inline-flex h-4 w-4 rounded-full bg-accent opacity-75"></span>
                            <span className="relative inline-flex rounded-full h-3 w-3 bg-accent"></span>
                        </span>
                    ) : (
                        <Steps>{idx + 1 || 0}</Steps>
                    )}

                    <Typography size="md" allBold className="flex-1">
                        {name}
                    </Typography>
                </div>

                <div className="flex items-center">
                    {!is_past_stage && is_crowdfunding && (
                        <Typography
                            allBold={true}
                            size="sm"
                            className={`text-right flex items-center ${
                                idx === now_live_idx ? 'text-accent' : ''
                            }`}
                            variant={'custom'}
                        >
                            {timer_value}
                        </Typography>
                    )}
                </div>
            </div>
            {(whitelist_stage && is_crowdfunding) || (active && Component) ? (
                <div className="border-l-2 border-border border-opacity-10 ml-3 mt-3 pl-[30px]">
                    <Component {...props} />
                </div>
            ) : null}
        </div>
    );
};

export const ExpandableStages: React.FC<{
    options: StageOption[];
    active_stage: number;
    event: ProjectEvent;
    has_reached_cutoff: boolean;
    is_event_over: boolean;
    hardcap: string;
    total_raised: string;
    className?: string;
}> = ({
    options,
    active_stage,
    event,
    has_reached_cutoff,
    is_event_over,
    hardcap,
    total_raised,
    className,
}) => {
    return (
        <div className={`space-y-[10px] ${className}`}>
            {options.map((o, idx) => (
                <Stage
                    has_reached_cutoff={has_reached_cutoff}
                    key={o.name}
                    idx={idx}
                    active_stage={active_stage}
                    option={o}
                    total={options.length}
                    event={event}
                    options={options}
                    is_event_over={is_event_over}
                    hardcap={hardcap}
                    total_raised={total_raised}
                />
            ))}
        </div>
    );
};
