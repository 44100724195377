import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { usePrevious } from 'react-use';
import { useRouter } from 'next/router';

import type { AppDispatch, RootState } from '@src/bootstrap/store';
import { CONTENT } from '@src/config';

import { useBuildID } from './useBuildID';

export * from './pages';

export {
    useContract,
    useDecubateContract,
    useERC20Contract,
} from './useContract';
export { useCountdown } from './useCountdown';
export { useForceUpdate } from './useForceUpdate';
export { useIntersection } from './useIntersection';
export { useIsomorphicLayoutEffect } from './useIsomporphicLayoutEffect';
export { usePairReserves } from './usePairReserves';
export { useSortSearch } from './useSortSearch';
export { useTokenApproval } from './useTokenApproval';
export { useTokenBalance } from './useTokenBalance';
export { useVerifyEmail } from './pages/useVerifyEmail';
export { useTokenPriceWei } from './useTokenPriceWei';
export * from './useKYC';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useGoogleTag = () => {
    // Google gtag
    useEffect(() => {
        TagManager.initialize({ gtmId: CONTENT.gtmId });
    }, []);
};

export const useLatestBuild = () => {
    const router = useRouter();

    const { buildID } = useBuildID();
    const prevBuildId = usePrevious(buildID);

    useEffect(() => {
        if (prevBuildId && buildID && prevBuildId !== buildID) {
            router.reload();
        }
    }, [buildID, prevBuildId]);
};

export const useLoginRedirects = () => {
    const router = useRouter();
    const [prevRoute, setPrevRoute] = useState('');

    useEffect(() => {
        const onRouteChange = (url) => {
            if (url.includes('login') && prevRoute !== '/login') {
                localStorage.setItem('afterLoginRoute', prevRoute);
            } else {
                localStorage.setItem('afterLoginRoute', '/');
            }
            setPrevRoute(url);
        };
        router.events.on('routeChangeStart', onRouteChange);
        return () => router.events.off('routeChangeStart', onRouteChange);
    }, [router, prevRoute]);
};
