import { MainEventCard } from '@decub8/ui';

import { ProjectContextProvider } from '@src/components/Project/context';
import { Project } from '@src/ts/interfaces';

import { useMainEventCard } from './hooks';

export enum DisplayStatus {
    Ongoing = 'Ongoing',
    Upcoming = 'Upcoming',
}

export const MainEventCardComponent: React.FC<{
    next_project: Project;
}> = ({ next_project }) => {
    const props = useMainEventCard(next_project);

    return <MainEventCard {...props} />;
};

export const MainEventCardContainer: React.FC<{
    next_project: Project;
    className?: string;
}> = ({ next_project }) => {
    return (
        <ProjectContextProvider
            project={next_project}
            event={
                next_project?.events[0]
                    ? {
                          ...next_project.events[0],
                          id: Number(next_project.events[0]?.id), // need to id convert to number
                      }
                    : undefined
            }
            children={<MainEventCardComponent next_project={next_project} />}
        />
    );
};
