import React from 'react';
import { PartnerSection as PartnerSectionUI } from '@decub8/ui';

import { IContentSection } from '@src/ts/interfaces';

// for some reason I had to wrap the partner section in a div so margin would work in the component below it

export const PartnerSection: React.FC<{
    section: IContentSection;
    className?: string;
}> = ({ section, className }) => {
    return (
        <div className={className}>
            <PartnerSectionUI
                title={section.title}
                logos={section.images.map(({ url, link }) => ({
                    image: url,
                    link,
                }))}
            />
        </div>
    );
};
