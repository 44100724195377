import { useEffect, useState } from 'react';

import { ContractType, EventType } from '@src/ts/constants';
import { ProjectEvent } from '@src/ts/interfaces';

import { useGlobalContext } from '../useGlobalContext';
import { useToken } from '../useToken';

export const useEventToken = (event: ProjectEvent) => {
    const { contract_manager } = useGlobalContext();
    const [token_address, setTokenAddress] = useState('');

    useEffect(() => {
        if (
            !contract_manager ||
            !event.contract?.address ||
            !event.contract.address
        )
            return;

        const wrapper = contract_manager.getContractByAddressAndABI(
            event.contract.address,
            JSON.parse(event.contract.abi),
            event.chainId,
        );

        let promise = null;
        switch (event.type) {
            case EventType.Crowdfunding:
                promise = wrapper.contract.vesting();
                break;
            case EventType.TokenClaim:
                promise = wrapper.contract._vesting();
                break;
            default:
                promise = Promise.reject('Invalid event type');
                break;
        }

        promise
            .then(async (address: string) => {
                // todo: get token address from vewsting
                const vesting_wrapper = contract_manager.getContractByAddress(
                    address,
                    ContractType.PlatformVesting,
                    event.chainId,
                );

                const token_address =
                    await vesting_wrapper.contract.vestedToken();

                setTokenAddress(token_address);
            })
            .catch((err) =>
                console.error('Error getting event token address', {
                    err,
                    event,
                }),
            );
    }, [event, contract_manager]);

    return useToken(token_address);
};
