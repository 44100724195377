import { gql } from '@apollo/client';

export const USER = {
    CHANGE_PASSWORD: gql`
        mutation ChangePassword(
            $email: String!
            $token: String!
            $password: String!
        ) {
            changePassword(email: $email, token: $token, password: $password) {
                success
            }
        }
    `,
    CLAIM_REFERRAL_REWARD: gql`
        mutation ClaimReferralReward {
            claimReferralReward
        }
    `,
    CONFIRM_2FA: gql`
        mutation Confirm2FA($secret: String!) {
            confirm2FA(secret: $secret) {
                id
                email
                first_name
                last_name
                role
                enabled_2fa
                active
                identity_verified
                nickname
                dob
                country
                wallet_address
                country_supported
                account_complete
                referral_id
                messages {
                    content
                }
            }
        }
    `,
    CONFIRM_REGISTER: gql`
        mutation ConfirmRegister(
            $email: String!
            $secret: String!
            $session: String!
            $captcha: String
        ) {
            confirmRegister(
                email: $email
                secret: $secret
                session: $session
                captcha: $captcha
            ) {
                access_token
                refresh_token
            }
        }
    `,
    DISMISS_MESSAGE: gql`
        mutation DismissMessage($message: String!) {
            dismissMessage(message: $message)
        }
    `,
    ENABLE_2FA: gql`
        mutation Enable2FA {
            enable2FA
        }
    `,
    LOGIN: gql`
        mutation Login($email: String!, $password: String!, $captcha: String) {
            login(email: $email, password: $password, captcha: $captcha) {
                access_token
                refresh_token
                requires_2fa
                requires_email_verification
                session
            }
        }
    `,
    INVESTED_IN_PROJECT: gql`
        mutation InvestedInProjectMutation($id: String!) {
            investedInProject(id: $id) {
                success
            }
        }
    `,
    REFRESH_ACCESS_TOKEN: gql`
        mutation RefreshAccessToken($refresh_token: String!) {
            refreshAccessToken(refresh_token: $refresh_token) {
                access_token
            }
        }
    `,
    REGISTER: gql`
        mutation Register(
            $email: String!
            $country: String!
            $password: String!
            $referred_by: String
            $nickname: String
            $captcha: String
        ) {
            register(
                email: $email
                country: $country
                password: $password
                referred_by: $referred_by
                nickname: $nickname
                captcha: $captcha
            ) {
                success
                session
            }
        }
    `,
    RESEND_VERIFICATION_EMAIL: gql`
        mutation ResendVerificationEmail($email: String!, $captcha: String) {
            resendVerificationEmail(email: $email, captcha: $captcha) {
                success
            }
        }
    `,
    RETRIEVE_ME: gql`
        query RetrieveMe {
            retrieveMe {
                id
                email
                first_name
                last_name
                role
                enabled_2fa
                active
                identity_verified
                nickname
                dob
                country
                wallet_address
                country_supported
                account_complete
                referral_id
                referred_by
                telegram_id
                messages {
                    content
                }
            }
        }
    `,
    REQUEST_PASSWORD_CHANGE: gql`
        mutation RequestChangePassword($email: String!, $captcha: String) {
            requestChangePassword(email: $email, captcha: $captcha) {
                success
            }
        }
    `,
    GENERATE_IDENTITY_CHECK_SESSION: gql`
        mutation GenerateIdentityCheckSession {
            generateIdentityCheckSession
        }
    `,
    UPDATE_USER: gql`
        mutation UpdateUser(
            $id: String!
            $first_name: String
            $last_name: String
            $account_complete: Boolean
            $dob: String
            $wallet_address: String
        ) {
            updateUser(
                id: $id
                first_name: $first_name
                last_name: $last_name
                account_complete: $account_complete
                dob: $dob
                wallet_address: $wallet_address
            ) {
                id
            }
        }
    `,
    SET_TELEGRAM_ID: gql`
        mutation SetTelegramId($telegram_id: String!) {
            setTelegramId(telegram_id: $telegram_id)
        }
    `,
    VERIFY_2FA: gql`
        mutation Verify2FA(
            $secret: String!
            $email: String!
            $session: String!
            $captcha: String
        ) {
            verify2FA(
                secret: $secret
                email: $email
                session: $session
                captcha: $captcha
            ) {
                access_token
                refresh_token
                requires_2fa
            }
        }
    `,
    VERIFY_WALLET_ADDRESS: gql`
        mutation VerifyWallletAddress($address: String!) {
            verifyWalletAddress(address: $address)
        }
    `,
    GENERATE_REFERRAL_ID: gql`
        mutation generateReferralId {
            generateReferralId {
                id
                email
                first_name
                last_name
                role
                enabled_2fa
                active
                identity_verified
                nickname
                dob
                country
                wallet_address
                country_supported
                account_complete
                referral_id
                messages {
                    content
                }
            }
        }
    `,
    RETTRIEVE_UNSUPPORTED_COUNTRYS: gql`
        query RetrieveUnsupportedCountries {
            retrieveUnsupportedCountries
        }
    `,
};
